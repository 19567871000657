var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.receipt,
            title: "供应商付款单详情",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.receipt = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    "label-width": "150px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单号", prop: "bill_code" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "单号", disabled: "" },
                        model: {
                          value: _vm.form.bill_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bill_code", $$v)
                          },
                          expression: "form.bill_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "供应商", prop: "supp_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "供应商", disabled: "" },
                        model: {
                          value: _vm.form.supp_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_name", $$v)
                          },
                          expression: "form.supp_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "\t联系人", prop: "supp_contact" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "\t联系人", disabled: "" },
                        model: {
                          value: _vm.form.supp_contact,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_contact", $$v)
                          },
                          expression: "form.supp_contact",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "\t联系人电话", prop: "supp_phone" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "\t联系人电话" },
                        model: {
                          value: _vm.form.supp_phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_phone", $$v)
                          },
                          expression: "form.supp_phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "采购单号", prop: "order_ori_code" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "采购单号" },
                        model: {
                          value: _vm.form.order_ori_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "order_ori_code", $$v)
                          },
                          expression: "form.order_ori_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款人", prop: "pay_user_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "付款人" },
                        model: {
                          value: _vm.form.pay_user_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pay_user_name", $$v)
                          },
                          expression: "form.pay_user_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款金额", prop: "pay_amount" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "付款金额" },
                        model: {
                          value: _vm.form.pay_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pay_amount", $$v)
                          },
                          expression: "form.pay_amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款账户", prop: "payment_account" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "\t付款账户" },
                        model: {
                          value: _vm.form.payment_account,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "payment_account", $$v)
                          },
                          expression: "form.payment_account",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款时间", prop: "create_at" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "\t付款时间" },
                        model: {
                          value: _vm.form.create_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "create_at", $$v)
                          },
                          expression: "form.create_at",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.receipt = !_vm.receipt
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }