<!--
 * @Author: your name
 * @Date: 2021-02-08 11:46:11
 * @LastEditTime: 2021-02-08 12:09:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\finance\supplier\receipt\components\link.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="receipt"
      title="供应商付款单详情"
      center
    >
      <div>
        <el-form ref="form" :model="form" inline label-width="150px">
          <el-form-item label="单号" prop="bill_code">
            <el-input
              v-model="form.bill_code"
              placeholder="单号"
              disabled
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="供应商" prop="supp_name">
            <el-input
              v-model="form.supp_name"
              placeholder="供应商"
              disabled
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="	联系人" prop="supp_contact">
            <el-input
              v-model="form.supp_contact"
              placeholder="	联系人"
              disabled
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="	联系人电话" prop="supp_phone">
            <el-input
              v-model="form.supp_phone"
              disabled
              placeholder="	联系人电话"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="采购单号" prop="order_ori_code">
            <el-input
              v-model="form.order_ori_code"
              disabled
              placeholder="采购单号"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="付款人" prop="pay_user_name">
            <el-input
              v-model="form.pay_user_name"
              disabled
              placeholder="付款人"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="付款金额" prop="pay_amount">
            <el-input
              v-model="form.pay_amount"
              disabled
              placeholder="付款金额"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="付款账户" prop="payment_account">
            <el-input
              v-model="form.payment_account"
              disabled
              placeholder="	付款账户"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="付款时间" prop="create_at">
            <el-input
              v-model="form.create_at"
              disabled
              placeholder="	付款时间"
              style="width: 220px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="receipt = !receipt">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        receipt: false,
        url: {
          supptable: '/cashAdmin/supp/payment-add-list',
          userlist: '/baseAdmin/common/user-list',
          supplier: '/purchaseAdmin/base/supp-list',
          account: '/baseAdmin/common/account',
          info: '/cashAdmin/supp/base-detail',
        },
        form: {
          bill_code: '',
          supp_name: '',
          supp_contact: '',
          supp_phone: '',
          order_ori_code: '',
          pay_user_name: '',
          pay_amount: '',
          payment_account: '',
          create_at: '',
        },
        account: [],
        supplier: [],
        userdata: [],
        tableData: [],
        celund: [
          {
            label: '应付款单',
            align: 'center',
            prop: 'bill_code',
          },
          {
            label: '关联采购单',
            align: 'center',
            prop: 'order_ori_code',
          },
          {
            label: '下单时间',
            align: 'center',
            prop: 'create_at',
          },
          {
            label: '剩余应付款金额',
            align: 'center',
            prop: 'arrears_amount',
          },
        ],
        sup: {
          id: '',
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        summe: [],
      }
    },
    computed: {
      money() {
        var sum = 0
        this.summe.forEach((list) => {
          sum += Number(list.arrears_amount)
        })
        return sum
      },
    },
    mounted() {
      this.handlersupplier()
      this.hanlderuserlist()
      this.handleraccount()
    },
    methods: {
      handlerData(row) {
        console.log(row, '')
        postAction(this.url.info, { id: row.id })
          .then((res) => {
            console.log(res, '')
            this.form = res.data
            if (res.code == 200) {
            } else {
              this.$message({
                message: '这个是接口的错',
                type: 'error',
              })
            }
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlersave() {
        postAction(this.url.save, this.form)
          .then((res) => {
            console.log(res, '')
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.$emit('getlist')
            this.receipt = false
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerSelection(val) {
        console.log(val, '')
        this.summe = val
        var ids = new Array()

        val.forEach((list) => {
          ids.push(list.id)
        })
        this.form.arrears_ids = ids.join(',')

        console.log(ids)
      },
      getRowKey(row) {
        return row.id
      },
      handlersupp(val) {
        console.log('供应商', val)
        this.sup.id = val
        this.handlersup()
      },
      handlersup() {
        postAction(this.url.supptable, this.sup)
          .then((res) => {
            console.log(res, '供应商付款单')
            this.tableData = res.data.rows
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.sup.pageSize = val
        this.handlersup()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.sup.pageNo = val
        this.handlersup()
      },
      // 收款账户
      handleraccount() {
        postAction(this.url.account, {})
          .then((res) => {
            console.log(res, '收款账户')
            this.account = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //供应商
      handlersupplier() {
        postAction(this.url.supplier, {})
          .then((res) => {
            console.log(res, '供应商')
            this.supplier = res.data.rows
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, {})
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style></style>
